import React, { useState, useContext, useEffect } from "react";
import "./setting.css";
import Form from "react-bootstrap/Form";
import { CrossIcon } from "../../common-componants/icons/icons";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { NextIcon } from "../../common-componants/icons/icons";
import "../earn/earn.css"
import { userInstance } from "../../config/axios";
import { useLocation } from "react-router-dom";

function Setting() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const {user, setUser} = useContext(UserContext)
  // const [exName,setExName] = useState("")
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(()=>{
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  },[])

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const deleteAccount = async() => {
    const resp = await userInstance().post("/deleteAccount", {
      userId:user.userId
    })
    setUser(resp.data)
    window.Telegram.WebApp.close()
  }

  // const getExchange=async()=>{
  //   try {
  //     const resp = await userInstance().post("/getExchange",{
  //       userId: user?._id,
  //     })
  //     // window.alert(resp.data)
  //     setExName(resp.data)
  //     console.log(resp.data)
  //   } catch (error) {
  //     console.log("get exchange error", error);
  //   }
  // }
  // useEffect(()=>{
  //   getExchange()
  // },[])
  return (
    <>
      <div className="settingMainWrap px-3">
        <div className="Settingwrap pt-3">
          <h1>Settings</h1>
        </div>
        <div className="firstaselectDiv">
          <div
              className="cardSection p-2 mt-3 d-flex align-items-center position-relative"
              onClick={()=>navigate("/choose-exchange")}>
              <div className="contentWrap">
                  <h2>Choose Your Diety</h2>
                  {user?.selectedExchange ?
                    <p className="mb-0">
                      {user?.selectedExchange}
                    </p>
                    : 
                    <p>Default Diety</p>
                  }
              </div>
              <div className="nextIcon">
                <NextIcon />
              </div>
          </div>
          
        </div>
        <div className="deleteAccountWrap">
          <button onClick={showPopup}>Delete Account</button>
        </div>
        {/* <div className="toggleWrap position-relative mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <p>Haptic Feedback</p>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <p>Coins Animation</p>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </div> */}
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap py-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="contentWrappopup text-center">
            <h1>Are you sure you want to delete your account?</h1>
            <p>
              All your data, including game progress, achivements, and
              purchases, will be permanently deleted. This action cannot be
              undone.
            </p>
          </div>
          <div className="btnsDivWrap">
            <button className="deleteBtn" onClick={()=>deleteAccount()}>Delete account</button>
            <button className="cancelBtn" onClick={hidePopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Setting;
