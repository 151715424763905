import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import "./play.css";
import "./a.css";
import gameImg from "../../../../assets/daily-task.png";
import gameImg1 from "../../../../assets/talents.png";
// import gameImg2 from "../../../../assets/Daily-Cipher.png";
import gameImg2 from "../../../../assets/easter-egg.png";
import gameImg3 from "../../../../assets/estereggnew.png";
import goldCoin from "../../../../assets/gold-coin.png";
import EnergyImg from "../../../../assets/lighting.png";
import tapBunny from "../../../../assets/bunnie.png";
import UserContext from "../../../../context/userContext";
import { userInstance } from "../../../../config/axios";
import BoostImg from "../../../../assets/Rocket.png";
import { Link } from "react-router-dom";
import RedChipperPage from "../../../../pages/redChipper";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../../../common-componants/icons/icons";
import { formatNumberWithCommas, formatToK } from "../../../mine/utils";
import socket from "../../../../config/socket";
import { toast } from "react-toastify";
import Zeus from "../../../../assets/icons/Zeus.png";
import Hera from "../../../../assets/icons/Hera.png";
import Poseidon from "../../../../assets/icons/Poseidon.png";
import Athena from "../../../../assets/icons/Athena.png";
import Krishna from "../../../../assets/icons/Krishna.png";
import Varaha from "../../../../assets/icons/Varaha.png";
import Rama from "../../../../assets/icons/Rama.png";
import Odin from "../../../../assets/icons/Odin.png";
import Loki from "../../../../assets/icons/Loki.png";
import Thor from "../../../../assets/icons/Thor.png";
import Balder from "../../../../assets/icons/Balder.png";
import Wukong from "../../../../assets/icons/Sun Wukong.png";
import checkImg from "../../../../assets/correct.png";
import useUpgradeUserLevel from "../../../../utils/useUpgradeUserLevel";

let newPositions = [];
let countervalue = 0;

function PlayScreen() {
  const { user, setUser, redScreen, setRedScreen } = useContext(UserContext);
  // const [goldCoinCount, setGoldCoinCount] = useState("Loadin...");
  const [flyNumber, setFlyNumber] = useState(false);
  // const [redScreen, setRedScreen] = useState(false);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [minedAmount, setMinedAmount] = useState(0);
  const [showOnce, setShowOnce] = useState(false);
  const [exchangeCalled, setExchangeCalled] = useState(false);
  const [isDailyRewardCollected, setIsDailyRewardCollected] = useState(false);
  const [positions, setPositions] = useState([]);
  const [hoursLeft, setHoursLeft] = useState("00");
  const [minuteLeft, setMinuteLeft] = useState("00");
  const [tiltStyle, setTiltStyle] = useState({ transform: "none" });
  const circleRef = useRef(null);
  const coinsRef = useRef(null); // Reference to the coin display
  const [updateOnce, setUpdateOnce] = useState(true);
  const [showAnimation, setShowAnimation] = useState(false);
  const [exchangeIcon, setExchangeIcon] = useState();
  const [coins, setCoins] = useState();
  const [displayCoins, setDisplayCoins] = useState(0);
  const [activeTouches, setActiveTouches] = useState(0);
  const [targetId, setTargetId] = useState(null);
  const [coin, setCoin] = useState([]);
  const [energy, setEnergy] = useState(user?.remainingPerMinute);

  const restrictedDivRef = useRef(null);

  // usePreventScroll(restrictedDivRef)
  useUpgradeUserLevel(user, setUser)

  const resetDefault = () => {
    newPositions = [];
    countervalue = 0;
  }

  useEffect(()=>{
    setEnergy(user?.remainingPerMinute);
  },[user?._id])

  

  useEffect(() => {
    setEnergy(user?.remainingPerMinute)
    if (window.Telegram.WebApp && redScreen) {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        setRedScreen(false);
        WebApp.BackButton.hide();
      });
    }
  }, [redScreen]);

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];


  //-------click for tilting background of tap area----------
  const handleCircleClick = (event) => {
    // if (navigator.vibrate) {
    //   console.log(navigator.vibrate, "hiiiiiiiiii")
    //   navigator.vibrate(200);
    // }
    const circle = circleRef.current;
    if (!circle) return;

    const rect = circle.getBoundingClientRect();

    const circleCenterX = rect.left + (rect.right - rect.left) / 2.5;
    const circleCenterY = rect.top + (rect.bottom - rect.top) / 2.5;

    const clickX = event.clientX;
    const clickY = event.clientY;

    const diffX = clickX - circleCenterX;
    const diffY = clickY - circleCenterY;

    const tiltX = diffY * 0.13;
    const tiltY = -diffX * 0.13;

    setTiltStyle({ transform: `rotateX(${tiltX}deg) rotateY(${tiltY}deg)` });

    setTimeout(() => {
      setTiltStyle({ transform: "none" });
    }, 150);
  };

//------to show selected exchange icon in popup
//------Pop up will appear if user have buy any card
  useEffect(() => {
    exchanges.map((exchange) => {
      if (exchange.name === user?.selectedExchange) {
        setExchangeIcon(exchange.Icon);
      }
    });
    return () => {
      resetDefault()
    };
  }, [user?.selectedExchange]);


  //---to show popup to collect coins for the time user was offline---------
  useEffect(() => {
    if (isPopupVisible) {
      const handleTouchOrClick = (event) => {
        exchangeWorkUpdate();
        triggerAnimation("animate-coin");

        const totalCoins = coins + parseInt(minedAmount);
        animateCoinsIncrement(displayCoins, totalCoins);
        setCoins(totalCoins);
        if (window.Telegram.WebApp) {
          const { WebApp } = window.Telegram;
          WebApp.ready();
          const noti = WebApp.HapticFeedback;
          const triggerMultipleVibrations = (numVibrations) => {
            let i = 0;
            const interval = setInterval(() => {
              if (i >= numVibrations) {
                clearInterval(interval);
              } else {
                noti.notificationOccurred("success");
                i++;
              }
            }, 90);
          };
          const coinsCollected = 10;
          triggerMultipleVibrations(coinsCollected);
        }
        hidePopup();
      };

      window.addEventListener("touchstart", handleTouchOrClick);
      localStorage.setItem("showpopup", "false");
      return () => {
        window.removeEventListener("touchstart", handleTouchOrClick);
      };
    }
  }, [user, isPopupVisible]);

  useEffect(() => {
    window.addEventListener("unload", function (event) {
      localStorage.removeItem("showpopup");
    });
  }, []);

  const showPopup = async () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

//-----This will calculate how much coin get collected when user was offline ,to show it in popup--------
  const exchangeWork = async () => {
    // const now = new Date()
    try {
      const resp = await userInstance().get("/getTime");
      const now = new Date(resp?.data);
      const lastOnline = new Date(user?.onLineFrom);
      const timeDifferenceInHours = (now - lastOnline) / (60 * 60 * 1000);
      const booster = user?.remainingPerMinute;
      const exchangeWorkingHours = booster / 1000;
      const pph = user?.minutlyGain?.value;
      if (timeDifferenceInHours > exchangeWorkingHours) {
        const mineAmount = parseFloat(exchangeWorkingHours) * parseFloat(pph);
        if (mineAmount >= 0) {
          setExchangeCalled(true);
        }
        setMinedAmount(mineAmount);
      } else {
        const mineAmount = parseFloat(timeDifferenceInHours) * parseFloat(pph);
        if (mineAmount >= 0) {
          setExchangeCalled(true);
        }
        setMinedAmount(mineAmount);
      }
    } catch (error) {
      toast.error(error.message, { toastId: "getTime" });
    }
  };

//------when user first interact with screen, then this function runs, popup will be closed and calculated coin will
//------added to user's account---
  const exchangeWorkUpdate = async () => {
    try {
      await userInstance().post("/exchangeWorkUpdate", {
        userId: user.userId,
        value: minedAmount,
      });
    } catch (error) {
      toast.error(error.message, { toastId: "exchangeWorkUpdate" });
      console.log("error in exchangeWorkUpdate", error);
    }
  };

//---update that is comes online---------
  const updateUserStatus = async (status) => {
    try {
      await userInstance().post("/userStatus", {
        userId: user.userId,
        status: status,
      });
    } catch (error) {
      toast.error(error.message, { toastId: "updateUserStatus" });
      console.log("update user status error", error);
    }
  };
  useEffect(() => {
    if (user) {
      if (exchangeCalled === false) {
        exchangeWork();
      }
      const show = localStorage.getItem("showpopup");
      if (minedAmount > 0 && !showOnce && show === "true") {
        setShowOnce(true);
        updateUserStatus("online");
        showPopup();
      }
      if (user?.minutlyGain?.value === 0 && updateOnce) {
        setUpdateOnce(false);
        updateUserStatus("online");
      }
    }
  }, [minedAmount, user]);

//------To check that user have collected today's reward or not-------------
  const date = new Date();
  date.setHours(5, 30, 0, 0);
  const today = date.toISOString().replace(".000Z", ":000Z");
  const getClaimReward = async () => {
    try {
      const resp = await userInstance().post("/getClaimReward", {
        userId: user?._id,
      });
      const rewardss = resp?.data?.rewards;
      for (let i = 0; i < rewardss.length; i++) {
        const reward = rewardss[i];
        if (
          reward.lastClaimedDate.slice(0, 10) === today.slice(0, 10) &&
          reward.claimed === true
        ) {
          setIsDailyRewardCollected(true);
          return;
        }
      }
    } catch (error) {
      toast.error(error.message, { toastId: "getClaimReward" });
      console.log("get exchange error", error);
    }
  };
  useEffect(() => {
    if (user) {
      getClaimReward();
    }
  }, []);

//----when user click on easter egg then we have to update that cards details with Play----
  const handleChangeScreen = (title) => {
    if (title === "Daily Tasks") {
      navigate("/earn");
    }
    if (title === "Easter Eggs" || title === "Play") {
      resetDefault()
      setPositions([])
      setEnergy(user?.remainingPerMinute)
      if (redScreen) {
        setRedScreen(false);
      } else {
        setRedScreen(true);
      }
    }
    if (title === "Talents") {
      navigate("/mine");
    }
  };
  useEffect(() => {
    setCoins(parseInt(user?.tokensWhenComesOnline));
    setDisplayCoins(parseInt(user?.tokens));
  }, [user]);

//----To calculate how much time left to reset that section--------------
  const calculateTimeLeft = () => {
    const now = new Date();
    const currentUTCHours = now.getUTCHours();
    const currentUTCMinutes = now.getUTCMinutes();

    const hoursLeft = 23 - currentUTCHours;
    const minutesLeft = currentUTCMinutes === 0 ? 0 : 60 - currentUTCMinutes;
    const formattedHours = String(hoursLeft).padStart(2, "0");
    const formattedMinutes = String(minutesLeft).padStart(2, "0");
    setHoursLeft(formattedHours);
    setMinuteLeft(formattedMinutes);
  };

  useEffect(() => {
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const cardData = [
    {
      id: 1,
      title: "Daily Tasks",
      hour: hoursLeft,
      minutes: minuteLeft,
      image: gameImg,
    },
    {
      id: 2,
      title: !redScreen ? "Easter Eggs" : "Play",
      hour: hoursLeft,
      minutes: minuteLeft,
      image: !redScreen ? gameImg2 : gameImg3,
    },
    { id: 3, title: "Talents", hour: "00", minutes: "00", image: gameImg1 },
  ];

//------This socket runs with cron job which update online user's tokens if they have baught any cards----------
  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
      // setGoldCoinCount(
      //   parseFloat(updatedUser?.tokensWhenComesOnline).toFixed()
      // );
    });
  }, []);

//----socket when we tap on bunny, Our api runs and we get data in this socket--------
  useEffect(() => {
    socket.on("updateTokenn", (updatedUser) => {
      setUser((prevUser) => ({
        ...prevUser,
        tokens: updatedUser?.tokens,
        remainingPerMinute: updatedUser?.remainingPerMinute,
        tokensWhenComesOnline: updatedUser?.tokensWhenComesOnline,
      }));
    });
  }, []);

//------Promise to update token api on Tap--------------
  const updateToken = (user) => {
    return new Promise((resolve, reject) => {
      userInstance()
        .post("/updateToken", {
          userId: user?._id,
          levelNo: user?.level?.levelNo,
          remainingPerMinute: user?.remainingPerMinute,
          multi: user?.multitap?.enable,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

// we are showing equivalent energy not our database energy(remaining per minute), because in the backend it takes time
// to update remaining per minute, so we update state here so we can show smoother animation to user...
  useEffect(() => {
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => {
        if (prevEnergy < user?.level?.oneTimeUpdate) {
          const difference = user?.level?.oneTimeUpdate - energy;
          if (difference > user?.level?.updationEverySecond) {
            return prevEnergy + user?.level?.updationEverySecond;
          } else {
            return prevEnergy + 1;
          }
        }
        return prevEnergy;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [user]);


//-------Function to handle animation and functionality on Tap----------------
  const handleTouchStart = async (event) => {
    try {
      const integerValue = Math.floor(
        energy / user?.level.levelNo
      );
      if (event.touches.length > integerValue) {
        setPositions([]);
        newPositions = [];
      }
      if (energy > 0 ) {
        setActiveTouches((prev) => prev + event.touches.length);
        const container = document.getElementById("container");
        const rect = container.getBoundingClientRect();

        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const radius = rect.width / 2;
        for (let i = 0; i < event.touches.length; i++) {
          const touch = event.touches[i];
          // Calculate the distance from the touch point to the center of the circle
          const distance = Math.sqrt(
            Math.pow(touch.clientX - centerX, 2) +
              Math.pow(touch.clientY - centerY, 2)
          );

          if (distance <= radius) {
            if (window.Telegram.WebApp) {
              const { WebApp } = window.Telegram;
              WebApp.ready();
              const noti = WebApp.HapticFeedback;
              noti.notificationOccurred("success");
            }
            const x = touch.clientX - rect.left;
            const y = touch.clientY - rect.top;

            const flyingNumberWidth = 50;
            const clampedX = Math.min(
              Math.max(x, 0),
              rect.width - flyingNumberWidth
            );
            const clampedY = Math.min(Math.max(y, 0), rect.height);

            if (energy > 0) {
              newPositions.push({
                top: clampedY,
                left: clampedX,
                countervalue: ++countervalue,
              });
              const difference = energy - user?.level?.levelNo;
              if (difference > 0) {
                setEnergy((prevEnergy) => {
                  if(user?.multitap?.enable){
                    const newEnergy = prevEnergy - (user?.level?.levelNo+2)
                    return newEnergy < 0 ? 0 : newEnergy;
                  }else{
                    const newEnergy = prevEnergy - user?.level?.levelNo
                    return newEnergy < 0 ? 0 : newEnergy;
                  }
                   
                });
              } else {
                setEnergy(0); 
              }  
              setDisplayCoins((prevCoins) => prevCoins + user?.level?.levelNo);
              if(user?.remainingPerMinute >11 && user?.remainingPerMinute > energy){
                updateToken(user)
                .then((response) => {
                  console.log("Token updated successfully");
                })
                .catch((error) => {
                  console.error("Error updating token:", error);
                });
              }
            } else {
              setEnergy(0);
            }
          } else {
            console.log("Touch outside circular area ignored");
          }
        }
        if (!flyNumber) {
          setFlyNumber(true);
        }

        const positionUpd = newPositions.slice(-50);

        setPositions((prevPosi) => {
          return [...positionUpd];
        });
      }
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message, { toastId: "tap-error" });
    }
  };

  const handleTouchEnd = async (event) => {
    if (positions.length && activeTouches - event.changedTouches.length <= 0) {
      // setTimeout(() => {
      //   // setFlyNumber(false);
      //   // setPositions([]);
      // }, 700);
    }
    setActiveTouches((prev) => Math.max(0, prev - event.changedTouches.length));
  };

  useEffect(() => {
    if (!flyNumber) {
      const timer = setTimeout(() => {
        setPositions([]);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [flyNumber]);

  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCoins((prevCoins) => prevCoins + 1);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

//----when user first time touch on screen and if there is popup to collect coin then this will show
//----his coins are increasing by that number.
  const animateCoinsIncrement = (start, end) => {
    const duration = 1000;
    const increment = (end - start) / (duration / 26); // Approximate frame rate to 60 FPS

    let current = start;

    const animate = () => {
      if (current < end) {
        current += increment;
        setDisplayCoins(Math.floor(current));
        requestAnimationFrame(animate);
      } else {
        setDisplayCoins(end);
      }
    };

    animate();
  };

  useEffect(() => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        animateCoinsToTarget(rect);
      }
    }
  }, [targetId]);

  const animateCoinsToTarget = (targetRect) => {
    let newCoins = [];
    for (let i = 0; i < 60; i++) {
      newCoins.push({
        id: i,
        // Randomize the initial top to be near the bottom, between window height - 100 and window height - 50
        top: window.innerHeight - (Math.random() * 50 + 50),
        // Randomize the initial left position to be spread across the width of the screen
        left: Math.random() * window.innerWidth,
        // Target is near the top at the target element
        targetTop: targetRect.top + Math.random() * 20,
        targetLeft: targetRect.left + Math.random() * 20,
      });
    }
    setCoin(newCoins);

    newCoins.forEach((coin, index) => {
      setTimeout(() => {
        setCoin((prevCoins) =>
          prevCoins.map((c) =>
            c.id === coin.id
              ? { ...c, top: coin.targetTop, opacity: 0, left: coin.targetLeft }
              : c
          )
        );
      }, index * 5);
    });
  };

  const triggerAnimation = (id) => {
    setCoin([coinsData]);
    setTargetId(id);
  };

  const coinsData = [
    { delay: "0.1s", left: "calc(50% - 400px)" },
    { delay: "0.2s", left: "calc(50% - 400px)" },
    { delay: "0.3s", left: "calc(50% - 500px)" },
    { delay: "0.4s", left: "calc(50% - 400px)" },
    { delay: "0.5s", left: "calc(50% - 400px)" },
    { delay: "0.1s", left: "calc(50% - 400px)" },
    { delay: "0.2s", left: "calc(50% - 320px)" },
    { delay: "0.3s", left: "calc(50% - 340px)" },
    { delay: "0.4s", left: "calc(50% - 340px)" },
    { delay: "0.5s", left: "calc(50%)" },
    { delay: "0.1s", left: "calc(50% + 300px)" },
    { delay: "0.2s", left: "calc(50% + 300px)" },
    { delay: "0.3s", left: "calc(50% + 300px)" },
    { delay: "0.4s", left: "calc(50% + 300px)" },
    { delay: "0.5s", left: "calc(50% + 300px)" },
    { delay: "0.1s", left: "calc(50% + 280px)" },
    { delay: "0.1s", left: "calc(50% - 320px)" },
    { delay: "0.2s", left: "calc(50% - 320px)" },
    { delay: "0.3s", left: "calc(50% - 320px)" },
    { delay: "0.4s", left: "calc(50% - 320px)" },
    { delay: "0.5s", left: "calc(50% - 320px)" },
    { delay: "0.1s", left: "calc(50% - 320px)" },
    { delay: "0.2s", left: "calc(50% - 320px)" },
    { delay: "0.3s", left: "calc(50% - 320px)" },
    { delay: "0.4s", left: "calc(50% - 320px)" },
    { delay: "0.5s", left: "calc(50%)" },
    { delay: "0.1s", left: "calc(50% + 280px)" },
    { delay: "0.2s", left: "calc(50% + 280px)" },
    { delay: "0.3s", left: "calc(50% + 280px)" },
    { delay: "0.4s", left: "calc(50% + 280px)" },
    { delay: "0.5s", left: "calc(50% + 280px)" },
    { delay: "0.1s", left: "calc(50% + 280px)" },
    { delay: "0.2s", left: "calc(50% + 280px)" },
    { delay: "0.3s", left: "calc(50% + 240px)" },
    { delay: "0.4s", left: "calc(50% + 240px)" },
    { delay: "0.5s", left: "calc(50% + 240px)" },
    { delay: "0.15s", left: "calc(50% - 240px)" },
    { delay: "0.25s", left: "calc(50% - 240px)" },
    { delay: "0.35s", left: "calc(50% - 240px)" },
    { delay: "0.45s", left: "calc(50% - 200px)" },
    { delay: "0.55s", left: "calc(50% - 200px)" },
    { delay: "0.45s", left: "calc(50% - 200px)" },
    { delay: "0.55s", left: "calc(50% - 200px)" },
    { delay: "0.45s", left: "calc(50% - 200px)" },
    { delay: "0.55s", left: "calc(50% - 200px)" },
    { delay: "0.35s", left: "calc(50% - 180px)" },
    { delay: "0.55s", left: "calc(50% - 180px)" },
    { delay: "0.35s", left: "calc(50% - 180px)" },
    { delay: "0.55s", left: "calc(50% - 180px)" },
    { delay: "0.25s", left: "calc(50% - 180px)" },
    { delay: "0.45s", left: "calc(50% - 180px)" },
    { delay: "0.25s", left: "calc(50% - 180px)" },
    { delay: "0.15s", left: "calc(50% - 150px)" },
    { delay: "0.3s", left: "calc(50% - 150px)" },
    { delay: "0.4s", left: "calc(50% - 150px)" },
    { delay: "0.5s", left: "calc(50% - 150px)" },
    { delay: "0.1s", left: "calc(50% + 150px)" },
    { delay: "0.2s", left: "calc(50% + 150px)" },
  ];


  return (
    <>
      <div className="cardRoundedWrap containerss py-3 px-4 mt-3 play-screen">
        <div className="smallCardWrap d-flex justify-content-between align-items-center">
          {cardData.map((card) => (
            <div
              className="smallCard"
              key={card.id}
              data-aos="fade-up"
              onClick={() => handleChangeScreen(card.title)}
            >
              <img src={card.image} alt="" height="45px" />
              <p>{card.title}</p>
              <h4>
                {card.hour}:{card.minutes}
              </h4>
              {(card.title === "Daily Tasks" && isDailyRewardCollected) ||
              (card.title === "Combo" && user?.claimedCombo) ||
              (card.title === "Easter Eggs" && user?.claimedMorse) ? (
                <p className="rightTick">
                  <img src={checkImg} alt="" />
                </p>
              ) : (
                <span className="blinking-text"></span>
              )}
            </div>
          ))}
        </div>
        {!redScreen ? (
          <div className="roundedGameWrap text-center mt-3"   ref={restrictedDivRef}>
            <p className="PointerEvent">
              <img
                src={goldCoin}
                alt=""
                id="animate-coin"
                className="target-div"
              />
              {user ? formatNumberWithCommas(displayCoins) : null}
            </p>
            <div
              className={`circle-container`}
              ref={circleRef}
              onClick={handleCircleClick}
              style={tiltStyle}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              id="container"
              // data-aos="zoom-in"
            >
              <img src={tapBunny} alt="Rabbit" />
              {flyNumber &&
                user?.remainingPerMinute > 0 &&
                positions &&
                positions?.map((el, i) => {
                  // console.log("index", { el, i });
                  return (
                    <div
                      key={el.countervalue} // Using index as key since each touch is unique in this context
                      className="flying-number"
                      style={{ top: el?.top, left: el?.left }}
                    >
                      {user?.multitap?.enable
                        ? `+${user?.level?.levelNo + 2}`
                        : `+${user?.level?.levelNo}`}
                    </div>
                  );
                })}
            </div>
            <div className="text-white mt-1 d-flex justify-content-between align-items-center">
              <div>
                <img src={EnergyImg} alt="" className="me-0 mt-2" />{" "}
                <span className="energyNumber">
                  {`${
                    energy===undefined ? user?.remainingPerMinute : energy
                    // user?.remainingPerMinute >= 0
                    //   ? formatNumberWithCommas(parseInt(user?.remainingPerMinute))
                    //   : 0
                  } 
              / ${formatNumberWithCommas(
                parseInt(user?.level?.oneTimeUpdate)
              )}`}
                </span>
              </div>
              <div className="boostWrap">
                <Link to="/boost" style={{ textDecoration: "none" }}>
                  <img src={BoostImg} alt="" className="me-0" />{" "}
                  <span className="energyNumber">Boost</span>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <RedChipperPage />
        )}
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="binacePopupWrap walletWrap position-relative mt-5">
            <div className=" homePopupWrap popupCard text-center">
              <span className="roundedLogoSpan">
                <img
                  src={exchangeIcon ? exchangeIcon : tapBunny}
                  alt={exchangeIcon}
                  style={{
                    width: "65px",
                    height: "65px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                />
              </span>
              <h2 className="d-flex align-items-center justify-content-center">
                <img
                  src={goldCoin}
                  alt=""
                  style={{ height: "105px", marginRight: "5px" }}
                />
                {formatToK(minedAmount)}
              </h2>
              <h1 className="">Give a big thanks to the universe!</h1>
              {user?.selectedExchange === "" ? (
                <h1>Please select a Deity and get reward</h1>
              ) : null}
            </div>
          </div>
          <div className="BUTTONdIV btnsDivWrap airDropsBtnWrap">
            {user?.selectedExchange === "" ? (
              <button onClick={hidePopup}>Thank you Bunny</button>
            ) : (
              <button onClick={hidePopup}>
                Thank you {user?.selectedExchange}
              </button>
            )}
          </div>
        </div>
      )}

      {coin.map((coin) => (
        <div
          key={coin.id}
          className="coinss"
          style={{
            top: `${coin.top}px`,
            left: `${coin.left}px`,
            transition: "top 0.8s ease, left 0.8s ease, opacity 1s ease", // Faster opacity transition
            position: "absolute",
            opacity: coin.opacity, // Control opacity for fading
          }}
        >
          <img src={goldCoin} alt="" style={{ height: "23px" }} />
        </div>
      ))}
    </>
  );
}

export default PlayScreen;
