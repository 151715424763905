import React, { useContext, useEffect, useState } from "react";
import "./level.css";
import Slider from "react-slick";
import SliderImg from "../../assets/bunnie.png";
import bunnyImg from "../../assets/profile/bunnyImg.png";
import {
  CoinIcon,
  LeftIcon,
  NextIcon,
  RightIcon,
  YoutubeRed,
} from "../../common-componants/icons/icons";
import { userInstance } from "../../config/axios";
import UserContext from "../../context/userContext";
import Zeus from "../../assets/tap-icons/Zeus.png";
import Hera from "../../assets/tap-icons/Hera.png";
import Poseidon from "../../assets/tap-icons/Poseidon.png";
import Athena from "../../assets/tap-icons/Athena.png";
import Krishna from "../../assets/tap-icons/Krishna.png";
import Varaha from "../../assets/tap-icons/Varaha.png";
import Rama from "../../assets/tap-icons/Rama.png";
import Odin from "../../assets/tap-icons/Odin.png";
import Loki from "../../assets/tap-icons/Loki.png";
import Thor from "../../assets/tap-icons/Thor.png";
import Balder from "../../assets/tap-icons/Balder.png";
import Wukong from "../../assets/tap-icons/Sun Wukong.png";
import goldCoin from "../../assets//gold-coin.png";
import { formatNumberWithCommas, formatToK } from "../mine/utils";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function LevelBronze() {
  const [levels, setLevels] = useState([]);
  const { user } = useContext(UserContext);
  const [slidesStart, setSlidesStart] = useState();
  const [currentSlide, setCurrentSlide] = useState(user?.level?.levelNo);

  const [users, setUsers] = useState([]);
  const [fetchedLevels, setFetchedLevels] = useState({});
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  useEffect(() => {
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  }, []);

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];

  useEffect(() => {
    setLoading2(true);
    (async () => {
      const resp = await userInstance().get("/levels");
      // console.log("resp ==>", resp);
      const sortedLevels = resp.data.levels.sort(
        (a, b) => a.levelNo - b.levelNo
      );
      setLevels(sortedLevels);
      setLoading2(false);
    })();
  }, []);

  useEffect(() => {
    // setUsers([])
    // if (fetchedLevels[currentSlide]) {
    //   setUsers(fetchedLevels[currentSlide]);
    // } else {
    setLoading(true);
    (async () => {
      const resp = await userInstance().post("/getUsersByLevel", {
        level: currentSlide,
      });
      // console.log("resp ==>", resp.data);
      // setFetchedLevels((prevFetchedLevels) => ({
      //   ...prevFetchedLevels,
      //   [currentSlide]: resp.data,
      // }));
      // console.log("-------", fetchedLevels);
      setUsers(resp.data);
      setLoading(false);
    })();
    // }
  }, [currentSlide]);

  const clearUsers = () => {
    setUsers([]);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: (user?.level?.levelNo || 1) - 1,
    prevArrow: <SamplePrevArrow clearUsers={clearUsers} />,
    nextArrow: <SampleNextArrow clearUsers={clearUsers} />,
    afterChange: (index) => {
      setUsers([]);
      return setCurrentSlide(index + 1);
    },
    beforeChange: (index) => {
      setUsers([]);

    },
  };
  function SamplePrevArrow(props) {
    const { className, style, onClick, clearUsers } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={() => {
          clearUsers();
          onClick();
        }}
      >
        <LeftIcon />
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick, clearUsers } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={() => {
          clearUsers();
          onClick();
        }}
      >
        <RightIcon />
      </div>
    );
  }
  const sliderRepeat = levels; //[1, 2, 3, 4, 5];
  const gradients = [
    "radial-gradient(circle, rgba(205, 127, 50, 0.8), rgba(139, 69, 19, 0.18), rgba(110, 54, 19, 0))",
    "radial-gradient(circle, rgba(192, 192, 192, 0.8), rgb(169 169 169 / 18%), rgba(128, 128, 128, 0))",
    "radial-gradient(circle, rgba(255, 215, 0, 0.8), rgba(218, 165, 32, 0.18), rgba(184, 134, 11, 0))",
    "radial-gradient(circle, rgba(221, 239, 255, 0.9), rgba(187, 222, 251, 0.25), rgba(156, 204, 235, 0))",

    "radial-gradient(circle, rgba(173, 216, 230, 0.8), rgba(100, 149, 237, 0.18), rgba(70, 130, 180, 0))",
    "radial-gradient(circle, rgba(144, 238, 144, 0.8), rgba(60, 179, 113, 0.18), rgba(34, 139, 34, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 160, 122, 0.18), rgba(255, 105, 97, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 105, 180, 0.18), rgba(255, 192, 203, 0))",

    // new colors
    "radial-gradient(circle, rgba(255, 228, 181, 0.8), rgba(255, 165, 0, 0.18), rgba(255, 140, 0, 0))",
    "radial-gradient(circle, rgba(147, 112, 219, 0.8), rgba(138, 43, 226, 0.18), rgba(75, 0, 130, 0))",
    "radial-gradient(circle, rgba(224, 255, 255, 0.8), rgba(175, 238, 238, 0.18), rgba(0, 206, 209, 0))",
  ];
  return (
    <div className="levelWrapPadding">
      {loading2 ? (
        <div
          className="d-flex justify-content-center align-items-center  "
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="light" />
        </div>
      ) : (
        <div className="levelMainWrap">
          <div className="levelSliderWrap">
            <Slider {...settings}>
              {sliderRepeat.map((lvl, index) => {
                return (
                  <div>
                    <div
                      className="sliderWrap text-center m-auto px-3"
                      key={lvl.levelNo}
                      style={{
                        height: "100vh",
                        backgroundImage: gradients[index % gradients.length],
                      }}
                    >
                      <img src={SliderImg} alt="" />
                      {/* <div className="imgBg">
                  </div> */}

                      <div className="sliderContentWrap">
                        <h1>{lvl?.name}</h1>
                        <span>{lvl.value}</span>
                        {loading ? (
                          <div className="loader mt-5">
                            <Spinner animation="border" variant="light" />
                          </div>
                        ) : (
                          <>
                            {users.map((mappedUser, idx) => {
                              // const selectedExchange = exchanges.find(
                              //   (exchange) =>
                              //     exchange.name === mappedUser.selectedExchange
                              // );
                              return (
                                <>
                                  <div
                                    key={idx}
                                    className={`cardSection sliderCardSection p-2 mt-3 d-flex align-items-center position-relative ${
                                      user._id === mappedUser._id
                                        ? "activeAbsoluteCard"
                                        : ""
                                    } `}
                                  >
                                    <div className="sideImgWrapLavel">
                                      <img src={SliderImg} alt="" />
                                    </div>
                                    <div className="contentWrap">
                                      <div className="d-flex align-items-center ">
                                        <h2 className="ms-2 mt-2">
                                          {mappedUser.name}
                                        </h2>
                                      </div>
                                      <p className="mb-0 mt-1">
                                        <img
                                          src={goldCoin}
                                          alt=""
                                          className="lavelCoinsImg"
                                        />
                                        &nbsp;
                                        {formatToK(
                                          parseInt(mappedUser.tokens)
                                        )}{" "}
                                        &nbsp;
                                      </p>
                                    </div>
                                    <div className="nextIcon">
                                      <span>{idx + 1}</span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            {/* {lvl?.name === user?.level.name ? (
                        <div className="active cardSection sliderCardSection activeAbsoluteCard p-2 mt-3 d-flex align-items-center">
                          <div className="sideImgWrapLavel">
                            <img src={SliderImg} alt="" />
                          </div>
                          <div className="contentWrap">
                            <div className="d-flex align-items-center ">
                              <div className="lavelProfileImg">
                                <img src={bunnyImg} alt="deity" />
                              </div>
                              <h2 className="ms-2 mt-2">{user?.name}</h2>
                            </div>
                            <p className="mb-0 mt-1">
                              <img
                                src={goldCoin}
                                alt=""
                                className="lavelCoinsImg"
                              />
                              &nbsp;{formatToK(parseInt(user?.tokens))} &nbsp;
                            </p>
                          </div>
                          <div className="nextIcon friendUserWrap">
                            <span>2</span>
                          </div>
                        </div>
                      ) : null} */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}

export default LevelBronze;
