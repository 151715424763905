import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/home";
import Header from "./common-componants/header";
import "bootstrap/dist/css/bootstrap.min.css";
import BottomBar from "./common-componants/bottomBar";
import MinePage from "./pages/mine";
import FriendsPage from "./pages/friends";
import EarnPage from "./pages/earn";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import SettingPage from "./pages/setting";
import AirDropsPage from "./pages/airDrops";
import ReciveGiftPage from "./pages/reciveGift";
import LevelPage from "./pages/level";
import ChooseExchangePage from "./pages/exchange";
import { userInstance } from "./config/axios";
import UserContext from "./context/userContext";
import ProfilePage from "./pages/profile";
import BoostPage from "./pages/boost";
import RedChipperPage from "./pages/redChipper";
import socket from "./config/socket";
import InviteHandler from "./inviteHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WelcomeScreen from "./componants/home/welcomeScreen";

function App() {
  //bot
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [id, setId] = useState("");
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [kentId, setKentId] = useState("");
  const [pageload, setPageload] = useState(true);

  function throttle(func, delay) {
    let lastCall = 0;
    return function (...args) {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      return func(...args);
    };
  }

  const createUser = throttle(async (
    userId,
    firstname,
    lastname,
    username,
    isPremiumUser,
    kentId
  ) => {
    try {
      const checkUser = await userInstance().post("/create", {
        userId,
        firstname,
        lastname,
        username,
        isPremiumUser,
        invitedBy: kentId,
        // amount: 5000                  //in case of invite
      });
      setUser(checkUser?.data);
      localStorage.setItem("userid", checkUser?.data._id); 
      socket.emit("join", checkUser?.data._id);
    } catch (error) {
      toast.error(error.message, { toastId: "create-user" });
      console.log("something went wrong", error);
    }
  },3000);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageload(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (id !== "" && firstname !== "") {
      createUser(id, firstname, lastname, username, isPremiumUser, kentId);
      localStorage.setItem("showpopup", "true");
    }
  }, [id, firstname, kentId]);

  useEffect(() => {
    if (window.Telegram.WebApp) {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      const userDetails = WebApp.initDataUnsafe.user;
      // setUserData(userDetails);
      console.log("userDetails", userDetails);
      if (userDetails) {
        setUsername(userDetails.username);
        setFirstname(userDetails.first_name);
        setLastname(userDetails.last_name);
        setId(userDetails.id);
        // setIsPremiumUser(userDetails.is_premium)
        setIsPremiumUser(userDetails.is_premium);
        // setUsername(WebApp.platform)
      } else {
        setUsername("");
      }

      WebApp.setHeaderColor("#11111a");
      // WebApp.enableVerticalSwipes();
      WebApp.disableVerticalSwipes();
      WebApp.expand();
      WebApp.BackButton.hide();
    } else {
      console.error("Telegram WebApp object not found");
    }
  }, []);

  const [user, setUser] = useState();
  const [walletVisible, setWalletVisible] = useState(true);
  const [specialPurchase, setSpecialPurchase] = useState(false);
  const [redScreen, setRedScreen] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
    });
  }, []);

  useEffect(() => {
    // Disable context menu globally
    const disableContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", disableContextMenu);
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  // const handleTestingFunction = async () => {
  //   try {
  //     const checkUser = await userInstance().post("/create", {
  //       userId: "6698239985",
  //     });
  //     setUser(checkUser?.data);
  //     localStorage.setItem("userid", checkUser?.data._id);
  //     console.log('asssssdddddddddd',checkUser?.data._id )
  //     socket.emit("join", checkUser?.data._id);
  //   } catch (error) {
  //     console.log("Error in the test", error);
  //   }
  // };

  // useEffect(() => {
  //   handleTestingFunction();
  // }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        walletVisible,
        setWalletVisible,
        specialPurchase,
        setSpecialPurchase,
        redScreen,
        setRedScreen,
      }}
    >
      <Router>
        <div className="App">
          <InviteHandler setKentId={setKentId} firstname={firstname} />
          {/* <Header firstname={firstname} /> */}
          <main>
            <div className="content-wrapper">
              <Routes>
                {pageload ? (
                  <Route path="/" element={<WelcomeScreen />} />
                ) : (
                  <>
                    <Route path="/" element={<HomePage />} />
                    <Route path="mine" element={<MinePage />} />
                    <Route path="friends" element={<FriendsPage id={id} />} />
                    <Route path="earn" element={<EarnPage />} />
                    <Route path="airdrops" element={<AirDropsPage />} />
                    <Route path="setting" element={<SettingPage />} />
                    <Route path="reciveGift" element={<ReciveGiftPage />} />
                    <Route path="level" element={<LevelPage />} />
                    <Route
                      path="choose-exchange"
                      element={<ChooseExchangePage />}
                    />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="boost" element={<BoostPage />} />
                    <Route path="chipper" element={<RedChipperPage />} />
                  </>
                )}
              </Routes>
            </div>
          </main>
          <BottomBar />
          {/* <Footer /> */}
        </div>
      </Router>
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
