import React, { useContext, useState } from "react";
import "./bottomBar.css";
import {
  AxeIcon,
  CoinSvg,
  ExportIcon,
  FriendsIcon,
  LeaderBoard,
  SVGComponent,
  TalentsIcon,
} from "../icons/icons";
import { Link } from "react-router-dom";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
// import LeaderBoard from "../../assets/Leaderboard.svg";

function BottomBar() {
  const { user, setRedScreen } = useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const handleItemClick = (index, item) => {
    setActiveIndex(index);
    if (window.Telegram.WebApp && item.label !== "Play") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        // navigate('/')
        setActiveIndex(0);
        WebApp.BackButton.hide();
      });
    }
    if (window.Telegram.WebApp && item.label === "Play") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      setRedScreen(false);
      WebApp.BackButton.hide();
    }
  };

  const items = [
    { id: 1, label: "Play", icons: <SVGComponent />, route: "/" },
    { id: 2, label: "Talents", icons: <TalentsIcon />, route: "/mine" },
    { id: 3, label: "Friends", icons: <FriendsIcon />, route: "/friends" },
    { id: 4, label: "Earn", icons: <CoinSvg />, route: "/earn" },
    { id: 5, label: "Leader", icons: <LeaderBoard />, route: "/level" },
    { id: 6, label: "Airdrop", icons: <ExportIcon />, route: "/airdrops" },
  ];

  return (
    <div className="bottomBarMainDiv">
      <div className="d-flex justify-content-between align-items-center">
        {items.map((item, index) => (
          <Link
            to={item?.route}
            style={{ textDecoration: "none" }}
            key={item.id}
            onClick={() => handleItemClick(index, item)}
          >
            <p
              className={`bottomBarItem ${
                activeIndex === index ? "active" : ""
              } ${item.label === "Earn" ? "earn-tab" : ""}`}
            >
              {item.icons}
              <span>{item.label}</span>
              {item.label === "Earn" && !user?.claimedDailyReward && (
                <span className="earn-dot"></span>
              )}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BottomBar;
