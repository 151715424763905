import React, { useContext, useEffect, useState } from "react";
import "./friends.css";
import giftImg from "../../assets/gift.png";
import inviteImg from "../../assets/Invite-friends.png";
import {
  CoinIcon,
  CopyIcon,
  ReloadIcon,
  UserIcon,
} from "../../common-componants/icons/icons";
import UserContext from "../../context/userContext";
import { toast } from "react-toastify";
import { userInstance } from "../../config/axios";
import goldCoin from "../../assets/gold-coin.png";
import bunnyImg from "../../assets/profile/bunnyImg.png";
import SliderImg from "../../assets/bunnie.png";
import { formatToK } from "../mine/utils";

function Friends({ id }) {
  const { user, setUser } = useContext(UserContext);
  const [updatedUser, setUpdatedUser] = useState(user)
  const [isRotating, setIsRotating] = useState(false);

  const getUpdatedUser = async () => {
    try {
      const resp = await userInstance().post("/getUpdatedUser", {
        userId: user?._id,
      });
      setUser(resp.data);
      setUpdatedUser(resp.data)
    } catch (error) {
      toast.error(error.message, { toastId: "getUpdatedUser" });
      console.log("getUpdatedUser error", error);
    }
  };
  const handleRefresh = () => {
    setIsRotating(true);
    getUpdatedUser();
    setTimeout(() => setIsRotating(false), 1000);
  };

  const isTelegramApp = navigator.userAgent.includes("Telegram");
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const inviteLink = `https://t.me/bunnies_ai_bot/start?startapp=kentId${id}`;
  const encodedLink = encodeURIComponent(inviteLink);
  const tgInviteLink = `tg://msg_url?url=${encodedLink}`;

  const handleInvite = () => {
    if (isTelegramApp && isiOS) {
      // Specific handling for iOS Telegram in-app browser
      window.location.href = tgInviteLink;
      setTimeout(() => {
        window.location.href = `https://t.me/share/url?url=${encodedLink}`;
      }, 700); // Fallback after timeout
    } else if (isiOS) {
      // Handling for iOS devices outside Telegram app
      window.location.href = tgInviteLink;
      setTimeout(() => {
        window.location.href = `https://t.me/share/url?url=${encodedLink}`;
      }, 700); // Fallback after timeout
    } else if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(
        `https://t.me/share/url?url=${inviteLink}`
      );
    } else {
      window.location.href = inviteLink; // Fallback for browsers (if needed)
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        toast.success("Copied", { toastId: "Copied" });
      })
      .catch((err) => {
        toast.error("Failed to copy the invite link", { toastId: "copyerror" });
      });
  };

  const [friendsDetails, setFriendsDetails] = useState([]);

  useEffect(() => {
    const fetchFriendDetails = async (friendId) => {
      try {
        const response = await userInstance().post("/getFriendRecord", {
          userId: friendId,
        });
        return response.data; // Assuming the API returns { level, tokens }
      } catch (error) {
        console.error("Error fetching friend's details:", error);
        return null;
      }
    };

    // Fetch details for all friends
    const fetchAllFriendsDetails = async () => {
      if (updatedUser?.invitedFriends?.length > 0) {
        // Using map to fetch details for each friend
        const friendsDetailsPromises = updatedUser?.invitedFriends.map(async (friend) => {
          const details = await fetchFriendDetails(friend.userId);
          console.log("heee--------",details)
          if (details) {
            return {
              friendName: friend.friendName,
              userId: friend.userId,
              level: details.level, // Ensure that each friend's level and tokens are separately stored
              tokens: details.tokens,
            };
          }
      });

        // Waiting for all promises to resolve
        const results = await Promise.all(friendsDetailsPromises);
        // Filtering out null values in case of errors
        const filteredResults = results.filter((result) => result !== null);
        setFriendsDetails(filteredResults);
      }
    };

    fetchAllFriendsDetails();
  }, [updatedUser]);

  return (
    <div className="friendMainWrap px-3">
      <div className="textWrap mt-3 pt-3">
        <h1>Invite Friends!</h1>
        <p>You and your friend will recive bonuses</p>
      </div>
      <div className="cardSection p-3 mt-4 d-flex align-items-center">
        {/* data-aos="fade-right" */}
        <div className="FriendsIconWrap">
          <img src={giftImg} alt="" />
        </div>
        <div className="contentWrap">
          <h2>Invite a friends</h2>
          <p>
            <img src={goldCoin} alt="" />
            &nbsp;+5,000 &nbsp; <span> for you and your friend</span>
          </p>
        </div>
      </div>
      <div className="cardSection p-3 mt-2 d-flex align-items-center">
        <div className="FriendsIconWrap">
          <img src={inviteImg} alt="" />
        </div>
        <div className="contentWrap">
          <h2>Invite a friend with Telegram Premium</h2>
          <p>
            <img src={goldCoin} alt="" /> &nbsp;+25,000 &nbsp;{" "}
            <span> for you and your friend</span>
          </p>
        </div>
      </div>
      <div className="moreBonusesWrap mt-4">
        <div className="d-flex justify-content-between align-items-center mt-4">
          <span>List of your friends</span>
          <div onClick={() => handleRefresh()}>
            <ReloadIcon className={isRotating ? "rotate-animation" : ""} />
          </div>
        </div>
        <div className="inviteFriendWrap mt-3">
          {friendsDetails.length > 0 ? (
            friendsDetails.map((friend, index) => (
              // <p >
              //   {index + 1}. {friend.friendName} (ID: {friend.userId}) - Level:{" "}
              //   {friend.level}, Tokens: {friend.tokens}
              // </p>
              <div
                key={index}
                className="active cardSection p-2 mt-3 d-flex align-items-center position-relative"
              >
                <div className="sideImgWrapLavel">
                  <img src={SliderImg} alt="" />
                </div>
                <div className="contentWrap friendUserWrap">
                  <div className="d-flex align-items-center ">
                    <h2 className="">{friend.friendName}</h2>
                  </div>
                  <p className="mb-0">
                    <span style={{ color: "#fff" }} className="me-2">
                      {friend.level}
                    </span>
                    <img src={goldCoin} alt="" className="lavelCoinsImg ms-2" />
                    &nbsp;
                    <span style={{ color: "#fff" }}>
                      <span style={{ color: "#fff" }}>
                        {formatToK(parseInt(friend.tokens))}
                      </span>
                    </span>{" "}
                    &nbsp;
                  </p>
                </div>
                <div className="nextIconNew friendUserWrap">
                  {/* <p className="mb-0">
                    <img src={goldCoin} alt="" className="lavelCoinsImg ms-2" />
                    &nbsp;<span style={{color: "#fff", fontSize: "12px"}}>(+ 123)</span> &nbsp;
                  </p> */}
                </div>
              </div>
            ))
          ) : (
            // <p>You haven't invited anyone yet</p>

            <div className="inviteInputWrap mt-3">
              <p>You haven't invited anyone yet</p>
            </div>
          )}
          <div className="inviteInputBtnWrap d-flex align-items-center justify-content-between mt-3">
            <button className="inviteBtn heart" onClick={handleInvite}>
              Invite a friend <UserIcon className="ml-3" />
            </button>
            <button className="reloadBtn" onClick={handleCopyLink}>
              <CopyIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Friends;
