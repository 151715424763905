import openSocket from "socket.io-client";
import { server } from "./keys";

const socket = openSocket(server, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  query: `token=null`, // replace with your actual token if needed
  reconnection: true // This allows the socket to attempt reconnection automatically
});

socket.on("connect", () => {
  // console.log("Socket connected:", socket.id);
  // const userid = localStorage.getItem("userid");
  // console.log("User logged in:", userid);
  // if (userid) {
    
  // }
});

// Handle disconnect
socket.on("disconnect", (reason) => {
  console.log("Socket disconnected:", reason);
});

// Optional: Handle reconnection attempts
socket.on("reconnect_attempt", () => {
  console.log("Attempting to reconnect...");
});

// Optional: Handle successful reconnection
socket.on("reconnect", (attemptNumber) => {
  console.log("Reconnected successfully on attempt number:", attemptNumber);
});

export default socket;
