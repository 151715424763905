import React, { useContext, useEffect, useState } from "react";
import "./earn.css";
import checkImg from "../../assets/correct.png";
import {
  CrossIcon,
  NextIcon,
} from "../../common-componants/icons/icons";
import goldCoin from "../../assets/gold-coin.png";
import { userInstance } from "../../config/axios";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import teligram from "../../assets/teligram.png";
import instagram from "../../assets/insta.png";
import twitter from "../../assets/twitter.png";
import exchange from "../../assets/exchange.png";
import users from "../../assets/users.png";

function TaskList() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [updatedUser, setUpdatedUser] = useState(user);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible1, setIsPopupVisible1] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState();
  const [tasksAmount, setTasksAmount] = useState([]);
  const [links, setLinks] = useState([]);
  const [socialClicked, setSocialClicked] = useState(false)
  const [f3clicked, setF3clicked] = useState(false)

  const showPopup = async (data) => {
    setIsPopupVisible(true);
    setSelectedSocial(data);
  };
  const hidePopup = () => {
    setIsPopupVisible(false);
    setSocialClicked(false)
  };

  const showPopup1 = () => {
    setIsPopupVisible1(true);
  };
  const hidePopup1 = () => {
    setIsPopupVisible1(false);
  };

  const buttonClick = async (data, link) => {
    if(!socialClicked){
      try {
        setSocialClicked(true)
        const resp = await userInstance().post("/userEarnByTasks", {
          userId: user._id,
          task: data.task,
        });
        setUser(resp.data);
        setUpdatedUser(resp.data);
        toast.success("success",{toastId:"clicked"});
        redirectToLink(link);
        hidePopup();
      } catch (error) {
        toast.error(error.message,{toastId:"clickerror"});
      }
    }
  };

  const getOtherTask = async () => {
    try {
      const resp = await userInstance().get("/getOtherTask");
      setTasksAmount(resp.data.taskMapping);
      setLinks(resp.data.links);
    } catch (error) {
      toast.error(error.message, { toastId: "getOtherTask" });
    }
  };
  useEffect(() => {
    getOtherTask();
  }, []);

  const redirectToLink = (link) => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(link);
    } else {
      window.location.href = link;
    }
  };

  const checkInviteFriends = async () => {
    if(!f3clicked){
      try {
        if(user?.invitedFriends?.length===0){
          toast.error("Task not completed",{toastId:"checkInviteFriends"});
        }
        if (user?.invitedFriends.length >= 3) {
          const resp = await userInstance().post("/updateUserToken", {
            userId: user._id,
          });
          setUser(resp.data);
        } else {
          toast.error("Task not completed",{toastId:"checkInviteFriends"});
        }
      } catch (error) {
        toast.error(error.message,{toastId:"checkInviteFriends"});
      }
    }
  };
  const socialMediaData = [
    {
      name: "TG Group",
      task: "joinedTelegramGroup",
      link: "tgGroupLink",
      icon: teligram,
      Tname: "Join our TG group",
    },
    {
      name: "Telegram",
      task: "joinedTelegramChannel",
      link: "tgLink",
      icon: teligram,
      Tname: "Join our TG channel",
    },
    {
      name: "Instagram",
      task: "joinedInsta",
      link: "instaLink",
      icon: instagram,
      Tname: "Follow us on Instagram",
    },
    {
      name: "X",
      task: "joinedX",
      link: "xLink",
      icon: twitter,
      Tname: "Follow us on X",
    },
  ];

  return (
    <>
      <div className="bunnyYoutubereWrap paddingBottom mt-4 px-3">
        <h2>Tasks</h2>

        {socialMediaData.map((data) => {
          const currentTask = data.task;
          return (
            <div
              className="cardSection svgIconWrap py-2 px-3 mt-3 d-flex align-items-center position-relative"
              onClick={() => showPopup(data)}
            >
              <div className="TaskIcons">
                <img src={data.icon} alt="" />
              </div>
              <div className="contentWrap">
                <h2>{data.Tname}</h2>
                <p className="mb-0">
                  <img src={goldCoin} alt="" />
                  &nbsp;+{tasksAmount[currentTask]} &nbsp;
                </p>
              </div>
              <div className="nextIcon">
                {updatedUser?.socials[currentTask] ? <p className="mb-0" style={{ fontSize: "22px" }}>
                <img src={checkImg} alt="" />
              </p> : <NextIcon />}
              </div>
            </div>
          );
        })}
        <div
          className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative"
          onClick={() => navigate("/choose-exchange")}
        >
          <div className="TaskIcons">
            <img src={exchange} alt="" />
          </div>
          <div className="contentWrap">
            <h2>Select your deity</h2>
            <p className="mb-0">
              <img src={goldCoin} alt="" />
              &nbsp;+{tasksAmount["exchange"]} &nbsp;
            </p>
          </div>
          <div className="nextIcon">
            {updatedUser.selectedExchange !== "" ? (
              <p className="mb-0" style={{ fontSize: "22px" }}>
                <img src={checkImg} alt="" />
              </p>
            ) : (
              <NextIcon />
            )}
          </div>
        </div>
        <div
          className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative"
          onClick={()=>showPopup1()}
        >
          <div className="TaskIcons">
            <img src={users} alt="" />
          </div>
          <div className="contentWrap">
            <h2>Invite 3 friends</h2>
            <p className="mb-0">
              <img src={goldCoin} alt="" />
              &nbsp;+{tasksAmount["invite3Friend"]} &nbsp;
            </p>
          </div>
          <div className="nextIcon">
            {user?.socials["invited3Friends"] ?(
              <p className="mb-0" style={{ fontSize: "22px" }}>
                <img src={checkImg} alt="" />
              </p>
              )       
              :
                <NextIcon />
            }
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap earnPopup text-center pt-5">
            {selectedSocial.icon ? (
              // <FontAwesomeIcon icon={selectedSocial.icon} color="#E6B1BA" />
              <img src={selectedSocial.icon} alt="" />
            ) : null}
            <h1>{selectedSocial.Tname}</h1>
            <button
              onClick={() => redirectToLink(links[selectedSocial.link])}
              className="popupJoinBtn"
            >
              Join
            </button>
            <p>
              You will earn 5000 coins when you join us on {selectedSocial.name}
            </p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {tasksAmount[selectedSocial.task]}
              </p>
            </div>
            {updatedUser.socials[selectedSocial.task] ? (
              <button>Completed</button>
            ) : (
              <button
                onClick={() =>
                  buttonClick(selectedSocial, links[selectedSocial.link])
                }
              >
                Check
              </button>
            )}
          </div>
        </div>
      )}

      {isPopupVisible1 && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          <div className="crossBtnWrap">
            <span onClick={hidePopup1}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap earnPopup text-center pt-5">
            <h1>Invite 3 friends</h1>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {tasksAmount["invite3Friend"]}
              </p>
            </div>
            {user?.socials["invited3Friends"] ? (
              <button>Completed</button>
            ) : (
              <button onClick={() => checkInviteFriends()}>Check</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default TaskList;
