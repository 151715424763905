import { useEffect } from 'react';
import { userInstance } from '../config/axios';

// Custom hook for upgrading user level
const useUpgradeUserLevel = (user, setUser) => {
  // Function to determine user level based on token
  const determineUserLevel = (token) => {
    switch (true) {
      case token <= 5000:
        return { levelNo: 1 };
      case token <= 25000:
        return { levelNo: 2 };
      case token <= 100000:
        return { levelNo: 3 };
      case token <= 1000000:
        return { levelNo: 4 };
      case token <= 5000000:
        return { levelNo: 5 };
      case token <= 10000000:
        return { levelNo: 6 };
      case token <= 50000000:
        return { levelNo: 7 };
      case token <= 100000000:
        return { levelNo: 8 };
      case token <= 1000000000:
        return { levelNo: 9 };
      case token <= 18000000000:
        return { levelNo: 10 };
      case token > 18000000000:
        return { levelNo: 11 };
      default:
        return null;
    }
  };

  useEffect(() => {
    const level = determineUserLevel(user?.tokens);
    const currentLevel = level?.levelNo;

    const upgradeUserLevel = async () => {
      try {
        if (currentLevel !== user?.level.levelNo) {
          const response = await userInstance().post('/updateUserLevel', {
            userId: user?._id,
            level: currentLevel,
          });
          setUser(response.data);
          console.log('User level upgraded:', response.data);
        }
      } catch (error) {
        console.error('Error upgrading user level:', error);
      }
    };

    if (user?.tokens) {
      upgradeUserLevel();
    }
  }, [user?.tokens, user?.level.levelNo, user?._id, setUser]);
};

export default useUpgradeUserLevel;
