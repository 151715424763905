import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./boost.css";
import {
  CrossIcon,
  NextIcon,
} from "../../common-componants/icons/icons";
import goldCoin from "../../assets/gold-coin.png";
import EnergyImg from "../../assets/lighting.png";
import BoostImg from "../../assets/Rocket.png";
import ClickImg from '../../assets/click.png'
import MeadSpell from '../../assets/Mead_Spell.png'
import Battery from '../../assets/boosterImg.png'
import EnergyGif from '../../assets/solar-energy-unscreen.gif'
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import socket from "../../config/socket";

function Boost() {
  const { user, setUser } = useContext(UserContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [type, setType] = useState()
  const [bClicked, setBClicked] = useState(false)
  const [mClicked, setMClicked] = useState(false)
  const [eClicked, setEClicked] = useState(false)
  const location = useLocation()

  const determineUserLevel = (token) => {
    switch (true) {
      case token <= 5000:
        return { levelNo: 1 }; 
      case token <= 25000:
        return { levelNo: 2};
      case token <= 100000:
        return { levelNo: 3};
      case token <= 1000000:
        return { levelNo: 4};
      case token <= 5000000:
        return { levelNo: 5};
      case token <= 10000000:
        return { levelNo: 6};
      case token <= 50000000:
        return { levelNo: 7};
      case token <= 100000000:
        return { levelNo: 8 };
      case token <= 1000000000:
        return { levelNo: 9 };
      case token <= 18000000000:
        return { levelNo: 10};
      case token > 18000000000:
        return { levelNo: 11 }; 
      default:
        return null; 
    }
  };
  useEffect(() => {
    const level = determineUserLevel(user?.tokens)
    const currentLevel = level?.levelNo
    
    const upgradeUserLevel = async () => {
      try {
        if(currentLevel !== user?.level.levelNo){
          const response = await userInstance().post('/updateUserLevel',
            { userId: user?._id,
              level: currentLevel 

            });
          setUser(response.data);
          console.log("User level upgraded:", response.data);
        }
      } catch (error) {
        console.error("Error upgrading user level:", error);
      }
    };
    upgradeUserLevel();
  }, [user?.tokens]);

  useEffect(()=>{
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  },[])

  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
    });
  }, []);

  const showPopup = (type) => {
    setType(type)
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const handleBooster = async ()=>{
    if(!bClicked){
      try {
        setBClicked(true)
        const resp = await userInstance().post("/addBooster", {
          userId: user._id
        });
        setUser(resp.data)
        toast.success("Booster added successfully",{toastId:"booster"})
        setBClicked(false)
        hidePopup()
      } catch (error) {
        toast.error("Unable to add booster.",{toastId:"error"})
        console.log("handle booster error", error);
      }
    }
  }

  const multiTap = async ()=>{
    if(!mClicked){
      try {
        setMClicked(true)
        const resp = await userInstance().post("/multiTap", {
          userId: user._id
        });
        setUser(resp.data)
        toast.success("Now enjoy your per tap with +2 for half an hour",{toastId:"multitap"})
        setMClicked(false)
        hidePopup()
      } catch (error) {
        toast.error(error.message,{toastId:"multiError"})
        console.log("multiTap error", error);
      }
    }
  }

  const energyLimit = async ()=>{
    if(!eClicked){
      try {
        setEClicked(true)
        const resp = await userInstance().post("/energyLimitBoseter", {
          userId: user._id
        });
        setUser(resp.data)
        toast.success("Energy limit has been increased by +500 for half an hour",{toastId:"energy"})
        setEClicked(false)
        hidePopup()
      } catch (error) {
        toast.error(error.message,{toastId:"eneryError"})
        console.log("energyLimit error", error);
      }
    }
  }

  function isLessThanOneHourBefore(date1, date2) {
    // Convert both dates to milliseconds
    const date1Millis = new Date(date1).getTime();
    const date2Millis = new Date(date2).getTime();
    // Calculate the difference in milliseconds
    const diffMillis = date2Millis - date1Millis;
    // Check if the difference is greator than one hour (3600000 milliseconds)
    return diffMillis > 0 && diffMillis > 3600000;
  }

  return (
    <>
      <div className="earnMainWrap px-3">
        <div className="contentMainWrap text-center  mt-4">
          <span>Your Stamina</span>
          <p>
            <img src={goldCoin} alt="" />
            {user?.remainingPerMinute}
          </p>
          {/* <p className="goldBoostTxt">How a boost work</p> */}
        </div>
        <div className="bunnyYoutubereWrap mt-3">
          <h2>Free daily boosters</h2>
          <div
            className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative"
            onClick={()=>showPopup("free")}
          >
            <div className="TaskIcons">
              {/* <EnergyIcon /> */}
              <img src={EnergyImg} alt="" />
            </div>
            <div className="contentWrap ms-3">
              <h2>Full energy</h2>
              <p className="mb-0">
                <span className="energyTxt">{user?.boosters.available}/6 available</span>
              </p>
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div>
          {/* <div className="cardSection p-2 mt-2 d-flex align-items-center position-relative">
            <div className="TaskIcons">
              <img src={BoostImg} alt="" />
            </div>
            <div className="contentWrap ms-3">
              <h2>Turbo</h2>
              <p className="mb-0">
                <span className="energyTxt">Coming soon</span>
              </p>
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div> */}
        </div>
        <div className="bunnyYoutubereWrap mt-5">
          <h2>Boosters</h2>
          <div className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative" onClick={()=>showPopup("multiTap")}>
            <div className="TaskIcons">
             <img src={MeadSpell} alt="" />
            </div>
            <div className="contentWrap">
              <h2>Mead Spell</h2>
              <p className="mb-0">
                <img src={goldCoin} alt="" /> &nbsp;4k &nbsp;
              </p>
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div>
          <div className="cardSection p-2 mt-2 d-flex align-items-center position-relative" onClick={()=>showPopup("energy")}>
            <div className="TaskIcons">
              <img src={Battery} alt="" />
            </div>
            <div className="contentWrap">
              <h2>Energy limit</h2>
              <p className="mb-0">
              <img src={goldCoin} alt="" /> &nbsp;4k &nbsp;
              </p>
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap earnPopup text-center">
          <img src={type==="free" ? EnergyGif :type==="multiTap" ? MeadSpell : Battery} alt="" />
            <h1>{type==="free" ? "Full Energy" :type==="multiTap" ? "Mead Spell" : "Energy limit"}</h1>
            <p>
              {type==="free" ? "Recharge your energy to maximum and do another round of mining" :type==="multiTap" ? 
              "Increase the amount of of coins you can earn on per tap" : 
              "Increase the amount of energy"}
            </p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            {/* <button onClick={showPopup}>Watch video</button> */}
            <div className="text-center mineNumberWrap">
              <p>
              <img src={goldCoin} alt="" />
              {type==="free" ? user?.level.oneTimeUpdate - user?.remainingPerMinute
               :type==="multiTap" ? 
              4000 : 
              4000}
              </p>
            </div>
            {type==="free" ? 
              <div>
                {user?.tokens > (user?.level.oneTimeUpdate - user?.remainingPerMinute) ?
                  isLessThanOneHourBefore(new Date(user?.boosters.lastApplied), new Date()) ?
                    <button disabled={!(user?.level.oneTimeUpdate - user?.remainingPerMinute)} onClick={handleBooster}>Go ahead</button>
                  :
                    <button>Already used! Come after an hour</button>
                :
                  <button>Already Full</button>
                } 
              </div>
            :type==="multiTap" ? 
            <div>
              {user?.tokens >4000 ?
                isLessThanOneHourBefore(new Date(user?.multitap.lastApplied), new Date()) ?
                  <button disabled={user?.multitap.enable} onClick={()=>multiTap()}>Go ahead</button>
                :
                  <button>Already used! Come after an hour</button>
              :
              <button>Insufficient balance</button>
              }
            </div> :
            <div> 
              {user?.tokens >4000 ?
                isLessThanOneHourBefore(new Date(user?.energyLimitBooster.lastApplied), new Date()) ?
                <button disabled={user?.energyLimitBooster.enable} onClick={()=>energyLimit()}>Go ahead</button>
                :
                  <button>Already used! Come after an hour</button>
              :
              <button>Insufficient balance</button>
              }
            </div>
            }     
          </div>
        </div>
      )}
    </>
  );
}

export default Boost;
